.img_width100 {
    width: 100%;
    height: auto;
    max-width: 100%;
}
.div_change_lang {
    display: flex;
    justify-content: end;
    width: 100%;
    // margin-top: 1rem;
}
.btn_change_lang {
    // background: #191f3a;
    // border-radius: 80px;
    background: transparent;
    border: none;
    // padding: 8px;
}
// MODAL
.modal_lang .modal-dialog {
    text-align: center;
    align-items: center;
    justify-content: center;
}
.modal_lang .modal-content {
    width: 90%;
    // background: #fff;
    // border-bottom: none !important;
    // box-shadow: none;
}
.modal_lang .modal-header {
    // background: #fff;
    // box-shadow: none;
    // border-bottom: none;
}
.title_popup_load {
    font-size: 18px !important;
    margin: 0 auto !important;
}
.div_modal_change_lang button {
    display: flex;
    // justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    background: transparent;
    border: none;
    padding: 5px 10px;
}
.div_modal_change_lang span {
    margin: 0 auto;
    color: #000;
}
.active_lang {
    border: 1px solid #ffe1a6 !important;
    padding: 5px 10px;
    border-radius: 1rem;
}
.div_lang_laos {
    margin-top: 1rem;
}
.css-1l8j5k8 {
    z-index: 1 !important;
}
@media only screen and (max-width: 765px) {}