.bg_img_style {
    background: #191e32;
    touch-action: manipulation;
    position: absolute;
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    background-position: center !important;
    background-attachment: fixed !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    width: 100% !important;
}
.div_container_login_register {
    width: 100%;
    margin: 0 auto;
    min-height: 100vh;
    box-sizing: unset !important;
}
.div_container_login_register_sec {
    width: 100%;
    margin: 0 auto;
    box-sizing: unset !important;
}
.div_container_login_register_sec_1 {
    position: fixed;
    top: 0px;
    right: 0px;
    left: 0px;
    pointer-events: none;
    width: auto;
    height: 3px;
    opacity: 0;
    background: #ffe1a6;
    transform: scaleX(0);
    transform-origin: left center;
    transition: transform 0.1s ease 0s, height 0.4s ease 0s, opacity 0.4s ease 0s;
    z-index: 999999;
    box-sizing: unset !important;
}
.div_container_main_login_register {
    backdrop-filter: blur(100px);
    background: radial-gradient(circle at 0 14.5%, #ffe1a6 -14%, transparent 24%), radial-gradient(circle at 96.5% 90.2%, #ffe1a6 -14%, transparent 18%);
    background-repeat: repeat-y;
    background-size: 100%;
    box-sizing: unset !important;
}
.div_container_main_login_register_sec {
    -webkit-tap-highlight-color: transparent;
    -webkit-backdrop-filter: blur(100px);
    backdrop-filter: blur(100px);
    position: relative;
    padding-left: 1rem;
    padding-right: 1rem;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
    display: flex;
    box-sizing: unset !important;
    justify-content: center;
}
.img_loginregister_main {
    width: auto;
    margin: 0rem auto 2rem auto;
    height: 7.5rem;
    cursor: pointer;
    box-sizing: unset !important;
}
.div_registerlogin_buttom {
    width: 100%;
    padding: 0.5rem 1rem;
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
    box-sizing: unset !important;
    border-radius: 0.5rem;
}
.container_main_v2 {
    margin-top: 1rem;
    padding: 0px !important;
}
.div_head_box_register_v2 {
    padding: 10px 0px;
    border-radius: 0.25rem !important;
}
.spn_tab_loginRegister {
    color: #fff;
    padding: 10px 60px;
    box-sizing: unset !important;
    font-size: 16px;
}
.div_container_login_v2 {
    width: 100%;
    margin: -10rem auto auto auto;
    padding: 10px;
}
.div_btn_login_v2 {
    margin-top: 1.5rem !important;
    margin-bottom: 1rem;
}
.btn_login_v2 {
    background-color: #1677ff;
    box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
    color: #fff !important;
    position: relative;
    height: 44px;
    width: 100%;
    min-width: 109px;
    padding: 0 15px;
    border: none !important;
    animation: rotate 3s linear infinite, opacityChange 3s infinite alternate;
    transform: perspective(1px) translateZ(0);
    border-radius: 0.5rem !important;
}
.btn_login_v2:hover {
    background-color: #2f86ff !important;
}
.password_header_v2 {
    color: #000 !important;
    background-color: #fff !important;
    border-radius: 0.5rem !important;
    border: 1px solid #d3d3d3 !important;
    height: 44px !important;
    padding-inline-start: 40px !important;
}
.password_header_v2:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #fff inset !important;
    -webkit-text-fill-color: #b1b1b1;
}
.telephone_header_v2 {
    color: #000 !important;
    background-color: #fff !important;
    border-radius: 0.5rem !important;
    border: 1px solid #d3d3d3 !important;
    height: 44px !important;
    padding-inline-start: 40px !important;
}
.telephone_header_v2:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #fff inset !important;
    -webkit-text-fill-color: #b1b1b1;
}
.phone_icon {
    position: absolute;
    margin: 10px;
    color: #5b5b5b;
}
.lock_icon {
    position: absolute;
    margin: 10px;
    color: #5b5b5b;
}
.div_img_logologin {
    width: 50%;
    margin: 0 auto;
}
@media only screen and (max-width: 765px) {
    .img_loginregister_main {
        margin: 0rem auto 1.5rem auto;
        height: 6.5rem;
    }
    .div_head_box_register_v2 {
        padding: 10px 0px 0px 0px;
    }
    .div_container_login_v2 {
        padding: 10px 20px;
        margin: -5rem auto auto auto;
    }
    .div_container_main_login_register_sec {
        min-height: 100vh;
        padding: 0rem 1rem 0rem 1rem;
    }
    .div_registerlogin_buttom {
        padding: 0px 0px 10px 0px;
        margin-bottom: 1rem;
    }
    .spn_tab_loginRegister {
        padding: 10px 35px;
    }
    .tab_loginRegister {
        padding: 12px 0px !important;
    }
}