.div_form_modal_adduser {
    margin-top: 1rem;
}
.div_modal_adduser {
    margin-top: 1rem;
    display: flex;
    justify-content: end;
}
.btn_edit {
    min-width: 32px !important;
    border-radius: 99px !important;
    font-size: 14px !important;
    height: 32px !important;
    padding: 0px !important;
    background-color: #ffc107 !important;
    box-shadow: 0 2px 0 #ffc107 !important;
    margin-right: 10px !important;
    & svg {
        color: #f9f9f9;
    }
    &:hover {
        background-color: #ffc107b7 !important;
    }
}
@media only screen and (max-width: 765px) {
    .btn_edit {
        margin-right: 5px !important;
    }
    .table_cell_btn {
        width: 20%;
    }
}