@import "custom-login";
@import "custom-dashboard";
@import "custom-siginhistory";
@import "custom-usermanagment";
@import "custom-scss";

@font-face {
  font-family: "Kanit";
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: local("Kanit"), url(../assets/font/Kanit-Light.woff) format("woff2");
}

* {
  font-family: "Kanit" !important;
}

html,
body {
  // overflow: auto !important;
  // background-color: #04040c;
  // background: url("../image/bg/main-bg.jpg");
  // height: 100%;
  margin: 0;

  // -webkit-background-size: cover;
  // -moz-background-size: cover;
  // -o-background-size: cover;
  // background-attachment: fixed;
  // background-size: cover;
  // background-repeat: no-repeat;
}