.div_dashboard_container {
    background-color: red;
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 100%;
    top: 0;
    left: 0;
    height: 56px;
    background: transparent;
    align-items: center;
    margin-block: 0;
    padding: 0px 20px;
    border-bottom: 1px solid #e8e8e8;
}
.img_w_h_auto_max100 {
    height: auto;
    width: auto;
    max-width: 100%;
}
.div_dashboard_logo {
    width: 50px;
    height: auto;
    display: flex;
    align-items: center;
}
.div_dashboard_logo a {
    text-decoration: auto;
    padding: 0px;
    margin: 0 auto;
    display: flex;
    align-items: center;
}
.div_dashboard_logo span {
    margin-left: 10px;
    // font-weight: bold;
    font-size: 18px;
    color: #000;
}
.div_dashboard_body {
    width: 100%;
    // padding: 0px 20px;
    // margin-top: .5rem;
}
.app_bar_container {
    background-color: transparent !important;
    border-block-end: 1px solid rgba(5, 5, 5, 0.06);
    background-color: rgba(255, 255, 255, 0.6);
    -webkit-backdrop-filter: blur(8px);
    backdrop-filter: blur(8px);
    transition: background-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
// DROP DOWN
.dropbtn {
    background-color: transparent;
    color: #00000073;
    padding: 10px;
    font-size: 16px;
    border: none;
    border-radius: .25rem;
    transition-duration: 500ms;
}
.dropdown {
    position: relative;
    display: inline-block;
}
.dropdown_content {
    display: none;
    position: absolute;
    background-color: #fff;
    min-width: 120px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    right: 0;
    border-radius: 0.5rem;
    color: #434343 !important;
}
.dropdown_content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}
.dropdown_content a:hover {
    background-color: #aaaaaa25;
    color: #000 !important;
    cursor: pointer;
    border-radius: 0.5rem;
}
.dropdown_content span {
    font-size: 14px;
}
.dropdown:hover .dropdown_content {
    display: block;
}
.dropbtn:hover {
    background-color: #aaaaaa25;
}
// END Dropdown
.toolbar_dashboard {
    display: flex !important;
    justify-content: end;
}
.div_profile_dashboard_container {
    display: flex;
    align-items: center;
}
.div_profile_dashboard {
    width: 28px;
    height: 28px;
}
.div_profile_dashboard_container span {
    margin-inline-start: 8px;
    font-size: 14px;
    text-align: center;
}
.spn_logout_dashboard {
    margin-inline-start: 8px;
}
// C_DASHBOARD MAIN
.div_dashboardmain_container {
    margin-top: 1rem;
}
.btn_svg_dashboard_sec1 {
    color: #fff;
    background-color: #1890ff;
    box-shadow: 0 2px 0 rgba(5, 175, 255, 0.1);
    font-size: 14px;
    height: 32px;
    padding: 4px 8px;
    border-radius: 6px;
    border: none;
}
.btn_category_svg_dashboard {
    width: 100%;
    color: #fff;
    background-color: #1890ff;
    box-shadow: 0 2px 0 rgba(5, 175, 255, 0.1);
    font-size: 14px;
    height: 32px;
    padding: 4px 8px;
    border-radius: 6px;
    border: none;
}
.div_dashboard_sec1 {
    display: flex;
    flex-wrap: wrap;
}
.div_dashboard_sec1_1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-bottom: 1rem;
}
.div_dashboard_sec1 button {
    margin-right: 10px;
    flex-grow: 1;
    flex-basis: 200;
}
.spn_svg_dashboard {
    margin-right: 5px;
}
.div_add_round_huay {
    margin-bottom: 1rem;
    width: 100%;
    display: flex;
    justify-content: end;
}
.div_lotto_all_title {
    width: 100%;
    display: flex;
    justify-content: end;
}
.btn_add_round_huay {
    color: #fff;
    background-color: #1890ff;
    box-shadow: 0 2px 0 rgba(5, 175, 255, 0.1);
    font-size: 16px;
    height: 40px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    border: 1px solid transparent;
}
.btn_add_huay {
    color: #fff;
    background-color: #1890ff;
    box-shadow: 0 2px 0 rgba(5, 175, 255, 0.1);
    font-size: 16px;
    height: 40px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    border: 1px solid transparent;
}
.btn_add_type_huay {
    color: #fff;
    background-color: #ffc107;
    box-shadow: 0 2px 0 rgba(251, 255, 5, 0.1);
    font-size: 16px;
    height: 40px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    border: 1px solid transparent;
    margin-right: 10px;
}
.active_filter_button {
    font-weight: bold;
    box-shadow: 2px 2px 2px black !important;
    color: #f1c40f !important;
    & .spn_active_filter {
        box-shadow: 2px 2px 2px black !important;
    }
}
.active_filter_btn_category {
    font-weight: bold;
    box-shadow: 2px 2px 2px black !important;
    color: #f1c40f;
}
.btn_dashboard_sec4 {
    color: #000;
    background-color: #66bb6a !important;
    border: 0px !important;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
    font-size: 14px;
    height: 32px;
    padding: 4px 15px;
    border-radius: 6px;
}
.btn_dashboard_sec4:hover {
    background-color: #81c784 !important;
}
.btn_dashboard_sec3 {
    color: #000;
    background-color: #bcbcbc !important;
    border: 0px !important;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
    font-size: 14px;
    height: 32px;
    padding: 4px 15px;
    border-radius: 6px;
}
.btn_dashboard_sec3:hover {
    background-color: #c3c3c3 !important;
}
.btn_dashboard_sec2 {
    color: #000;
    background-color: #ffa726 !important;
    border: 0px !important;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
    font-size: 14px;
    height: 32px;
    padding: 4px 15px;
    border-radius: 6px;
}
.btn_dashboard_sec2:hover {
    background-color: #ffb74d !important;
}
.icon_style {
    width: 30px;
    height: 30px;
    padding: 5px;
    color: #000000bb;
    font-size: 35px !important;
}
.flag {
    width: 40px;
    height: 28px;
    // margin-left: 12px;
    object-fit: fill;
    border-radius: .2rem;
    border: 1px solid #e7e7e7;
}
// .lotto_btn {
//     height: 100%;
//     padding: 0px !important;
// }
// .lotto_btn:hover {
//     padding: 0px !important;
//     transition: all 0.5s;
// }
// .lotto_btn:hover .ant-card {
//     border: 0px !important;
// }
.lotto_btn:hover {
    // padding: 0px !important;
    border: 0px !important;
    transition: all 0.5s;
}
.little_card {
    width: 100% !important;
}
.div_card_loop_huay a {
    text-decoration: none;
}
.card_loop_huay {
    padding: 10px;
    width: auto;
}
.div_del_lotto {
    display: flex;
    justify-content: end;
}
.div_del_lotto button {
    background-color: red;
    border: none;
    border-radius: .25rem;
    color: #fff;
    width: 100%;
}
.pin_group_lottothai input{
    width: 55px;
    font-size: 40px;
    text-align: center;
    border-radius: 10px;
    margin: 5px;
}
.pin_group_lottothai_sec {
    text-align: center;
}
.pin_group input {
    width: 55px;
    font-size: 40px;
    text-align: center;
    border-radius: 10px;
}
.pin_group input:first-child {
    margin-right: 10px;
}
.pin_group input:last-child {
    margin-left: 10px;
}

div:where(.swal2-container) {
    z-index: 99999 !important;
}
.form_style {
    padding: 14px;
    border: 1px solid #b1b1b1;
}
.div_lottoresult_top {
    margin-bottom: 1.5rem;
}
.div_lottoresult_top h2 {
    font-size: 24px;
    font-weight: bold;
}
.div_lottoresult_top p {
    cursor: pointer;
    color:#09f;
}
.div_lottoresult_top p:hover {
    color:rgb(255, 0, 0);
}
.card_lotto_result_container {
    box-shadow: none !important;
}
.div_lotto_result_container_title {
    padding: 15px;
    background: linear-gradient(90deg, #2c3352 0, #0c112c 53.75%);
}
.div_lotto_result_container_title2 {
    margin-bottom: 1rem;
}
.div_lotto_result_container_title3 {}
.div_lotto_result_container_title h3 {
    font-size: 20px;
    font-weight: bold;
}
.div_lotto_result_container_title3 h2 {
    font-size: 20px;
    font-weight: bold;
}
.btn_submit {
    background-color: #1890ff !important;
    color: #fff !important;
    border-radius: 0.5rem !important;
}
.syncoutlined {
    animation: laodingCicle 1s infinite linear;
}
.css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
    z-index: 1 !important;
}
.div_title_modal {
    display: flex;
    justify-content: space-between;
    margin-bottom: .5rem !important;
}
.div_title_modal h3 {
    font-size: 18px;
    margin: 0px;
    font-weight: bold;
}
.div_title_modal svg {
    cursor: pointer;
}
@keyframes laodingCicle {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
.btn_lotto_draw_modal {
    display: flex;
    justify-content: end;
}
.react-datetime-picker{
    width: 100%;
}
.react-datetime-picker__wrapper {
    border: thin solid #ebebeb !important;
    padding: 5px;
    border-radius: 0.5rem;
}
.div_lotto_all {
    // background-color: red;
    padding: 5px;
    border: 1px solid #ececec;
    border-radius: 0.25rem;
    height: 8.5rem;
}
.border_buttom {
    border-bottom: 1px solid #ececec;
    padding: 0px 0px 10px 0px;
}
.div_buttom_sec {
    display: flex;
    align-items: end;
}
.div_buttom_sec a {
    width: 100%;
}
.div_buttom_sec button {
    padding: 2px !important;
    font-size: 14px !important;
    width: 100%;
}
.div_btn_lottoall_delete_disble button {
    background-color: #dddddd;
    border: none;
    border-radius: 0.25rem;
    width: 100%;
    color: #fff;
}
.div_btn_lottoall_delete button {
    background-color: red;
    border: none;
    border-radius: 0.25rem;
    width: 100%;
    color: #fff;
}
.div_btn_lottoall_edit button{
    background-color: #ffc925;
    border: none;
    border-radius: 0.25rem;
    width: 100%;
    color: #fff;
}
.div_container_lotto_all {
    // padding: 0px 15px;
}
.card_container_lotto_all {
    border: none !important;
    box-shadow: none !important;
}
.card_container_lotto_category {
    padding: 20px 12px;
    background: #fff;
    border: 1px solid #d0d7de !important;
    border-radius: 12px;
}
.div_lotto_name {
    font-size: 16px;
    font-weight: bold;
}
.div_lotto_all_del_edit {
    display: flex;
    align-items: end;
}
.div_pn_create_day {
    display: flex;
    line-height: 2;
    justify-content: end;
}
.pn_create_day {
    font-size: 12px;
    background: #00000005;
    border: 1px solid #d9d9d9;
    padding: 2px 5px;
    border-radius: 0.25rem;
    // width: 100%;
    height: 100%;
    margin-inline-end: 7px;
    margin-bottom: 0px;
    white-space: nowrap !important;
}
.box_dashboard_main_container {
    width: 100%;
    max-width: 1980px;
    height: auto;
    min-width: 240px;
}
.btn_del_ctable_lotto {
    min-width: 32px !important;
    border-radius: 99px !important;
    font-size: 14px !important;
    height: 32px !important;
    padding: 0px !important;
    background-color: #ff4d4f !important;
    box-shadow: 0 2px 0 rgba(255, 38, 5, 0.06) !important;
    & svg {
        color: #f9f9f9;
    }
    &:hover {
        background-color: #ff7c7e !important;
    }
}
.input_lottodraw_pattern {
    border-start-end-radius: 0 !important;
    border-end-end-radius: 0 !important;
}
.select_lottodraw_pattern {
    border-start-start-radius: 0 !important;
    border-end-start-radius: 0 !important;
}
.div_result_lotto {
    display: flex;
    justify-content: end;
    text-align: center;
    & a {
        color: #fff;
        background-color: #ffc107;
        border: none;
        border-radius: 0.25rem;
        width: 100%;
    }
}
.show {
    // display: flex !important;
}
.hide {
    display: none !important;
}
.list_item_text_font span {
    font-size: 14px;
}
.list_icon_width svg{
    width: 20px;
}
.search_icon {
    position: absolute;
    margin: 7px 10px;
    color: #4e4e4e;
}
.search_header {
    padding-inline-start: 40px;
    border-radius: 0.5rem;
    color: #000 !important;
    background: transparent !important;
    border: #343a40 2px solid;
    padding: 2px 0px 4px 35px !important;
    height: 32px;
}
.search_header:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #000 inset !important;
    -webkit-text-fill-color: #b1b1b1;
}
.search_header::placeholder {
    opacity: 0.5 !important;
    color: rgb(180, 180, 180) !important;
}
.div_block_lotto {
    padding: 1rem .5rem;
    margin-top: 1rem !important;
    background: #fafafa;
    box-shadow: 0 0.125rem 0.25rem #00000013;
    border-radius: 0.25rem;
}
.title_huay_lotto {
    font-weight: bold;
    color: #283051;
    width: 100%;
    border-radius: 4px;
    margin-bottom: 10px;
    font-size: 20px;
    padding: 0px 16px !important;
}
.div_lotto_category {
    background: #fff;
    touch-action: manipulation;
    // position: absolute;
    -webkit-background-size: cover !important;
    -moz-background-size: cover !important;
    -o-background-size: cover !important;
    background-position: center !important;
    // background-attachment: fixed !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    // background-color: red;
    border-radius: 7px;
    box-shadow: 2px 2px 2px black !important;
    & p {
        border-radius: 7px 0px 0px 0px;
        padding: 10px 15px;
        margin-bottom: 0px;
        // border-radius: 7px;
        border-bottom: 1px solid #ececec;
        border-top: 1px solid #ececec;
        font-weight: bold;
    }
}
.div_lotto_category:hover {
    & p {
        // color: #fff;
    }
}
.div_btn_lotto_category {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    & button {
        border: none;
        border-radius: 0.25rem;
        width: 100%;
        margin: 0px 5px;
        color: #fcfcfc;
    }
}
.btn_edit_lotto_category {
    background-color: #ffc107;
}
.btn_edit_lotto_category:hover {
    background-color: #ffd558;
}
.btn_delete_lotto_category {
    background-color: red;
}
.btn_delete_lotto_category:hover {
    background-color: rgb(255, 84, 84);
}
.div_date_range {
    width: 100% !important;
    & .rdrMonths  {
        width: 100% !important;
        & .rdrMonth {
            width: 100% !important;
        }
    }
}
.div_select_date {
    border: 1px solid #d6d6d6;
    border-radius: 0.4rem;
    padding: 5px;
    cursor: pointer;
}
.div_select_date:hover {
    border: 1px solid #fff;
    background-color: #1890ff;
    color: #fff;
}
@media only screen and (max-width: 765px) {
    .div_select_date {
        width: 100%;
        text-align: center;
    }
    .ant-picker-dropdown {
        inset: 355px auto auto auto !important;
    }
    .lottodraw_modal .modal-content {
        margin-top: 4.5rem;
    }
    .div_search_lotto {
        margin-top: 1rem;
        width: 100%;
    }
    .btn_dashboard_sec3 {
        margin-top: 1rem;
    }
    .div_del_lotto {
        display: flex;
        justify-content: flex-start;
    }
    .div_result_lotto {
        & a {
            width: auto;
            padding: 1px 3px;
        }
    }
    .card_loop_huay {
        height: 160px;
        padding: 4px;
    }
    .flag {
        width: 40px;
        height: 28px;
    }
    .div_lotto_name {
        font-size: 14px;
        display: flex;
        justify-content: end;
        text-align: end;
    }
    .btn_lotto_draw_modal button {
        width: 100%;
    }
    .div_lottoresult_top h2 {
        font-size: 20px;
    }
    .stack_lottoresult {
        margin-top: 1rem;
        align-items: center !important;
    }
    .btn_add_round_huay {
        width: 100%;
        justify-content: center;
    }
    .toolbar_dashboard {
        justify-content: space-between
    }
    .btn_dashboard_sec4 {
        margin-top: 1rem;
    }
}